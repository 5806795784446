import { S25Util } from "../../util/s25-util";
import { ObjectSearchAvailabilityUtil } from "../../util/object.search.availability.util";
import { EventMircotI } from "../s25-event/EventMicroI";
import { AvailCheck } from "../../services/resource.space.avail.service";
import { AvailService } from "../../services/avail.service";
import { Proto } from "../../pojo/Proto";
import { S25WsNode } from "../../pojo/S25WsNode";
import { S25WsSpace } from "../../services/space.service";
import { S25WsResource } from "../../services/resource.service";
import { S25Event } from "../s25-event/EventMicroI";
import { S25Profile } from "../s25-event/ProfileI";
import { S25Reservation, ObjectType } from "../s25-event/ReservationI";
import { ResourceSpaceAvailService } from "../../services/resource.space.avail.service";
import { jSith } from "../../util/jquery-replacement";
import { Item } from "../../pojo/Item";

import ObjectAvail = AvailCheck.ObjectAvail;
import Reservation = AvailCheck.Reservation;
import SpaceReservation = AvailCheck.SpaceReservation;
import Conflict = AvailCheck.Conflict;
import ISODateString = Proto.ISODateString;
import AP = AvailCheck.AP;

type ResourceReservation = {
    resource_id: number;
    quantity?: number;
    conflicts: Conflict[];
};
declare global {
    interface Window {
        angBridge: any;
    }
}

export class S25OjectSearchUtil {
    public static async getAvailData(
        objectType: ObjectType,
        profileId: number,
        eventId: number,
        accumItems: any,
        event: EventMircotI,
        selectedItems: number[],
    ) {
        let reservations = await S25OjectSearchUtil.normalizeReservationData(objectType, accumItems, profileId, event);
        let specificOccs: any[] = reservations.filter((item: any) => selectedItems.includes(item.rsrv));
        return ResourceSpaceAvailService.checkAvailability(eventId, profileId, specificOccs, true).then(function (
            data: any,
        ) {
            // if (data.resourceConflicts){
            //      return S25OjectSearchUtil.checkedObj(accumItems,data.resourceConflicts, Item.Ids.Resource, specificOccs.length );
            // }
            // if (data.spaceConflicts){
            //      return S25OjectSearchUtil.checkedObj(accumItems,data.spaceConflicts, Item.Ids.Location , specificOccs.length);
            // }

            return S25OjectSearchUtil.checkedObj(accumItems, data, specificOccs.length);
        });
        // return ObjectSearchAvailabilityUtil.checkAvailability(eventId, profileId, specificOccs, accumItems, null);
    }

    public static async normalizeReservationData(
        objectType: ObjectType,
        accumItems: SpaceReservation[] | ResourceReservation[],
        profileId: number,
        event: EventMircotI,
    ) {
        let findProfile = event.profile.find((p: S25Profile) => {
            return p?.itemId === profileId;
        });
        let reservations: Reservation[] = [];

        if (findProfile?.reservations) {
            let count = 0;
            let space_reservation: SpaceReservation[] = [];
            let resource_reservation: ResourceReservation[] = [];
            if (objectType === "location") {
                space_reservation = accumItems.map((item: any) => {
                    return {
                        space_id: item.space_id,
                        share: "F",
                        always_shared: item.always_shared, // Assuming always_shared is "F" as there is no equivalent property in the input data
                        conflicts: [],
                    } as SpaceReservation;
                });
            }

            if (objectType === "resource") {
                resource_reservation = accumItems.map((item: any) => {
                    return {
                        resource_id: item.resource_id,
                        quantity: 1,
                        conflicts: [],
                    } as ResourceReservation;
                });
            }

            findProfile?.reservations.forEach((r: S25Reservation) => {
                if (r.state === "active") {
                    let rsrv = {
                        occUUID: count++,
                        reservation_start_dt: r?.setupStart || r.eventStart,
                        reservation_end_dt: r?.takeDownEnd || r.eventEnd,
                        space_reservation: space_reservation,
                        resource_reservation: resource_reservation,
                        rsrv: r.itemId,
                        state: { itemId: 1, itemName: "Active" },
                    };
                    reservations.push(rsrv);
                }
            });
        }
        return reservations;
    }

    public static checkedObj(
        accumItems: SpaceReservation[] | ResourceReservation[],
        reservations: any,
        numDatesToCheck: number,
    ) {
        let itemTypeId = reservations.resourceConflicts[0]?.itemTypeId || reservations?.spaceConflicts[0]?.itemTypeId;
        let checkedItems = reservations.spaceConflicts;
        if (itemTypeId === Item.Ids.Resource) checkedItems = reservations.resourceConflicts;

        jSith.forEach(accumItems, function (_, item) {
            item.checkedObj = {
                conflicts: [],
                hasConflicts: false,
                hasRealConflicts: false,
                totalDatesChecked: numDatesToCheck,
                availableDates: numDatesToCheck,
                conflictingDates: 0,
                availableDatesWithOverrides: numDatesToCheck,
            };
            item.apRestriction = undefined;

            jSith.forEach(checkedItems, function (_, rsrv) {
                if (itemTypeId === Item.Ids.Location) {
                    if (
                        item.itemId === parseInt(rsrv.itemId) &&
                        rsrv.apNoRequest &&
                        S25Util.date.equal(
                            S25Util.date.dropTZString(rsrv.apViolationDate),
                            S25Util.date.dropTZString(rsrv.candidateDate),
                        )
                    ) {
                        item.apRestriction = {
                            startDt: rsrv?.apViolationDate,
                            apNoRequest: true,
                            reason: rsrv.apReason,
                        };
                    }

                    if (item.itemId === parseInt(rsrv.itemId)) {
                        const findDateExit = item.checkedObj.conflicts.find(
                            (i: any) => i.itemId === parseInt(rsrv.itemId) && i.candidateDate === rsrv.candidateDate,
                        );
                        if (!findDateExit) item.checkedObj.availableDates -= 1;
                        S25OjectSearchUtil.setItemConflicts(item, rsrv, itemTypeId);
                    }
                }

                if (itemTypeId === Item.Ids.Resource) {
                    if (
                        item.itemId === parseInt(rsrv.resource_id) &&
                        rsrv.apNoRequest &&
                        S25Util.date.equal(
                            S25Util.date.dropTZString(rsrv.apViolationDate),
                            S25Util.date.dropTZString(rsrv.reservation_start_dt),
                        )
                    ) {
                        item.apRestriction = {
                            startDt: rsrv.apViolationDate,
                            apNoRequest: true,
                            reason: rsrv.apReason,
                        };
                    }

                    if (item.itemId === parseInt(rsrv.itemId)) {
                        const findConfilict = item.checkedObj.conflicts.find(
                            (i: any) =>
                                i.itemId === parseInt(rsrv.itemId) &&
                                i.candidateDate === rsrv.candidateDate &&
                                i.conflictHash == rsrv.conflictHash,
                        );
                        if (!findConfilict) S25OjectSearchUtil.setItemConflicts(item, rsrv, itemTypeId);
                    }
                }
            });

            if (item.itemTypeId === Item.Ids.Resource) {
                item.allResAvailDateMap = item.allResAvailDateMap || {};
                S25Util.extend(item.allResAvailDateMap, reservations.allResAvailDateMap);

                var unlimited = Math.pow(2, 31);
                var minAvailable = unlimited,
                    maxAvailable = -1,
                    stockTotal = item.stock_level || -1;

                jSith.forEach(reservations, function (_, rsrv) {
                    var available;
                    var key =
                        item.itemId +
                        "&" +
                        S25Util.date.toS25ISODateTimeStr(S25Util.date.parseDropTZ(rsrv.reservation_start_dt));
                    var resAvailDateObj = item.allResAvailDateMap[key];
                    if (resAvailDateObj) {
                        available = resAvailDateObj.stockLevel;
                        stockTotal = resAvailDateObj.stockTotal;
                    }

                    available = Math.max(S25Util.coalesce(available, unlimited), 0);

                    if (available > maxAvailable) {
                        maxAvailable = available;
                    }

                    if (available < minAvailable) {
                        minAvailable = available;
                    }

                    if (available > stockTotal) {
                        stockTotal = available;
                    }
                });

                item.quantity = stockTotal > -1 && stockTotal < unlimited ? stockTotal : "Unlimited";
                item.minAvailable = minAvailable;
                item.maxAvailable = maxAvailable;
                item.availability = "";

                if (item.maxAvailable === 0) {
                    //if no resource total available, then available dates should be 0, causing "Unavailable" to show for action
                    item.checkedObj.availableDatesWithOverrides = 0;
                    item.checkedObj.availableDates = 0;
                }

                if (item.quantity === "Unlimited") {
                    item.availability = "Unlimited";
                } else {
                    if (item.minAvailable === item.maxAvailable) {
                        item.availability = item.minAvailable + " / " + item.quantity;
                    } else {
                        item.availability = item.minAvailable + " to " + item.maxAvailable + " / " + item.quantity;
                    }
                }
            }

            item.checkedObj.conflicts.sort(S25Util.shallowSortDates("candidateDate", "conflictStart"));
        });

        return accumItems;
    }

    public static setItemConflicts(item: any, rsrv: any, itemTypeId: Item.Ids) {
        item.hasConflicts = true;
        item.checkedObj.availableDatesWithOverrides -= rsrv.isReal ? 1 : 0;
        item.checkedObj.conflictingDates += 1;
        item.checkedObj.conflicts.push({
            conflictRelation: rsrv.conflictRelation,
            conflictHash: rsrv.conflictHash,
            conflictType: rsrv.conflictType,
            conflictName: rsrv.conflictName,
            conflictStart: rsrv.conflictStart,
            conflictEnd: rsrv.conflictEnd,
            conflictShare: rsrv.conflictShare,
            conflictOverride: rsrv.conflictOverride,
            candidateDate: rsrv.candidateDate,
            occUUID: rsrv.occUUID,
            isReal: rsrv.isReal,
            candidateId: rsrv.candidateId,
            conflictId: rsrv.conflictId,
            date: rsrv.candidateDate,
            itemId: item.itemId,
            itemName: item.itemName,
            itemTypeId: itemTypeId,
        });
        item.checkedObj.hasConflicts = item.checkedObj.hasConflicts || rsrv.hasConflicts;
        item.checkedObj.hasRealConflicts = item.checkedObj.hasRealConflicts || rsrv.hasRealConflicts;

        return item;
    }
}

export interface AvailLocationItems extends S25WsSpace, Layout, CheckedObj {
    itemTypeId?: number;
    conflicts: Conflict[];
    share?: "F" | "T";
}
export interface AvailResourceItems extends S25WsResource, CheckedObj {
    allResAvailDateMap?: any;
    itemTypeId?: number;
    apRestriction?: any;
    availability?: number | undefined;
    maxAvailable?: number;
    minAvailable?: number;
    quantity?: number | undefined;
    conflicts: Conflict[];
}

export interface CheckedObj {
    conflicts: Conflict[];
    hasConflicts: boolean;
    hasRealConflicts: boolean;
    totalDatesChecked: number;
    availableDates: number;
    conflictingDates: number;
    availableDatesWithOverrides: number;
    buttonText?: string;
}

export interface Layout {
    layout_diagram_type?: string;
    layout_id?: number;
    layout_name?: string;
    layout_setup_tm?: string;
    layout_photo_name?: string;
    layout_description_id?: number;
    layout_photo_glyph?: string;
    layout_instruction?: string;
    layout_diagram_id?: number;
    layout_diagram_glyph?: string;
    default_layout?: "T" | "F";
    layout_photo_id?: number;
    layout_photo_type?: string;
    layout_tdown_tm?: string;
    layout_capacity?: number;
    layout_diagram_name?: string;
    layout_defn_state?: number;
    layout_description?: string;
    layout_instruction_id?: number;
    status?: S25WsNode["status"]; //'mod' | 'est' | 'del' | 'new' ;
    layout_ratio?: number;
}

export interface UrlParams {
    inputParam?: string;
    query?: string;
    criteria?: string;
    include?: string;
    page_size?: string; //&page_size=25
    page?: string; // "&page=1"
    paginate?: string; // "&paginate="
}
