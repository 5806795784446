import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-object-audit-additional-info")
@Component({
    selector: "s25-ng-object-audit-additional-info",
    template: `
        @if (itemName) {
            <div>
                <span>{{ itemName }}&nbsp;</span>
                <span class="ng25LiveGrey">{{ msg }}&nbsp;</span>
                <span>{{ dateTimeStr }}</span>
            </div>
        }
        @if (!itemName && auditType !== 108) {
            <span>{{ additionalInfo | datesInString: this.dateFormat }}</span>
        }
        @if (!itemName && auditType === 108) {
            <span [innerHTML]="htmlMsg | safeHTML"></span>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectAuditAdditionalInfoComponent implements OnInit {
    @Input() itemName?: string;
    @Input() msg?: string;
    @Input() dateTimeStr?: string;
    @Input() auditType?: number;
    @Input() additionalInfo?: string;
    @Input() htmlMsg?: string;
    @Input() dateFormat?: string;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.cd.detectChanges();
    }
}
