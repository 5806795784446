import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25ObjectAuditListComponent } from "./s25.object.audit.list.component";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25ObjectAuditAdditionalInfoComponent } from "./s25.object.audit.additional.info.component";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { DatesInStringPipe } from "../../pipes/s25.dates.in.string.pipe";

@NgModule({
    imports: [CommonModule, S25OptModule, S25TableModule, S25PipesModule, DatesInStringPipe],
    exports: [S25ObjectAuditListComponent, S25ObjectAuditAdditionalInfoComponent],
    declarations: [S25ObjectAuditListComponent, S25ObjectAuditAdditionalInfoComponent],
    providers: [S25ObjectAuditListComponent, S25ObjectAuditAdditionalInfoComponent],
})
export class S25ObjectAuditListModule {}
