import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Util } from "../../util/s25-util";
import { AuditService } from "../../services/audit.service";
import { OptUtil } from "../s25-opt/s25.opt.util";
import { Table } from "../s25-table/Table";
import { S25ObjectAuditAdditionalInfoComponent } from "./s25.object.audit.additional.info.component";
import { jSith } from "../../util/jquery-replacement";
import Cell = Table.Cell;
import { UserprefService } from "../../services/userpref.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { GenericTableFadePreviewComponent } from "../s25-table/generics/generic.table.fade.preview.component";

@TypeManagerDecorator("s25-ng-object-audit-list")
@Component({
    selector: "s25-ng-object-audit-list",
    template: `
        @if (init) {
            <s25-ng-opt [modelBean]="optBean" [hasRefresh]="true" [help]="'view_eventsingle_audit'"></s25-ng-opt>
        }
        @if (init) {
            <s25-ng-table
                [caption]="'Audit Trail'"
                [unlimitedWidth]="true"
                [columnSortable]="true"
                [dataSource]="auditDataSource"
                [hasTotalRowCount]="true"
            ></s25-ng-table>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectAuditListComponent implements OnInit {
    @Input() itemId: number;
    @Input() itemTypeId: number;

    @ViewChild(S25TableComponent) table: S25TableComponent;

    init: boolean = false;
    dateTimeFormat: string;
    modelBean: any;
    optBean: any;
    auditDataSource: Table.Paginated;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.auditDataSource = {
            type: "paginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "audit_date",
                    header: "Date",
                    sortable: true,
                },
                {
                    id: "audit_user",
                    sortable: true,
                    header: "User",
                },
                {
                    id: "audit_action",
                    sortable: true,
                    header: "Action",
                },
                {
                    id: "audit_info",
                    sortable: false,
                    header: "Additional Information",
                    content: { component: S25ObjectAuditAdditionalInfoComponent },
                },
            ],
            defaultPageSize: 25,
            pageSizes: [25, 50, 100],
            customRowCount: this.getRowCount,
        };

        S25Util.all({
            opt: OptUtil.formAuditListBean(this.optRefresh, {
                autoInit: false,
                hasOpt: true,
                itemId: this.itemId,
                itemTypeId: this.itemTypeId,
                searchContext: "audit_" + this.itemId + "&" + this.itemTypeId,
            }),
            dateTimeFormat: UserprefService.getS25DateTimeformat(),
        }).then((resp: any) => {
            this.optBean = resp.opt;
            this.dateTimeFormat = resp.dateTimeFormat;

            this.init = true;
            this.cd.detectChanges();
        });
    }

    optRefresh = () => {
        this.table.refresh(true, true);
    };

    getRowCount = (counts: Table.RowCounts) => {
        return `${counts.total} Matching Audit Records`;
    };

    getAuditData = (query: Table.PaginatedQuery) => {
        return AuditService.getAudits({
            itemId: this.itemId,
            itemTypeId: this.itemTypeId,
            modelBean: {
                itemsPerPage: query.pageSize,
                chosen: {
                    page: query.page + 1, // s25-table uses 0-based index by WS uses 1-based
                },
                sortCol: {
                    sort: query.sortColumn.id,
                    order: query.sortColumn.order,
                },
                cacheId: query.cacheId,
            },
        });
    };

    getRows = async (query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> => {
        let listData = await this.getAuditData(query);

        let rows: Table.Row[] = [];

        if (listData?.audit?.audit_item) {
            jSith.forEach(listData.audit.audit_item, (idx: number, obj: any) => {
                const cells: Table.Row["cells"] = {};

                for (let i = 0; i < this.auditDataSource.columns.length; i++) {
                    let column = this.auditDataSource.columns[i];
                    let cell: Cell = {};

                    switch (column.id) {
                        case "audit_date":
                            cell.text = S25Datefilter.transform(obj.audit_date, this.dateTimeFormat);
                            cell.sortValue = obj.audit_date;
                            break;
                        case "audit_user":
                            cell.text = S25Util.toStr(obj.user);
                            break;
                        case "audit_action":
                            cell.text = S25Util.toStr(obj.action);
                            break;
                        case "audit_info":
                            cell.component = GenericTableFadePreviewComponent;
                            let html = S25Util.toStr(obj.context?.html_msg)
                                .replace(/href\s*=\s*["'].*?["']/g, "") // no clickable links
                                .replace(/href\s*=\s*mailto.*?>/g, ">");
                            cell.inputs = {
                                cell: {
                                    component: S25ObjectAuditAdditionalInfoComponent,
                                    inputs: {
                                        itemName: obj.context?.itemName,
                                        msg: obj.context?.msg,
                                        dateTimeStr: S25Datefilter.transform(
                                            obj.additional_information,
                                            this.dateTimeFormat,
                                        ),
                                        auditType: S25Util.parseInt(obj.audit_type),
                                        additionalInfo: S25Util.toStr(obj.additional_information),
                                        htmlMsg: html,
                                        dateFormat: this.dateTimeFormat,
                                    },
                                },
                            };
                            break;
                    }

                    cells[column.id] = cell;
                }

                rows.push({
                    id: idx,
                    name: "" + idx,
                    cells: cells,
                });
            });
        }

        return {
            rows: rows,
            totalRows: S25Util.parseInt(listData?.audit?.total_results),
            cacheId: listData?.audit?.paginate_key,
        };
    };
}
