import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ModalCreateObjectModel } from "../modal/modals/modal.create.object.component";
import { ModalService } from "../modal/modal.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-create-object")
@Component({
    selector: "s25-ng-create-object",
    template: `<div>
        <button class="c-textButton createNew" (click)="initModal()">
            Create New {{ this.data.objectName ? this.data.objectName : "" }}
        </button>
    </div>`,
})
export class S25CreateObjectComponent implements OnInit, OnChanges {
    @Input() data: ModalCreateObjectModel;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit();
    }

    ngOnInit() {
        this.data.title = `Create ${this.data.objectName}`;
        this.cd.detectChanges();
    }

    initModal() {
        ModalService.modal("create-object", this.data);
    }
}
