import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectionStrategy,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { AvailLocationItems, AvailResourceItems } from "./s25.object.search.util";
import { EventOccUtil } from "../s25-event/s25-event-occurrences/s25-event-occurrence/s25.event.occurrence.util";
import { Table } from "../s25-table/Table";
import { EventMircotI } from "../s25-event/EventMicroI";
import { S25Reservation } from "../s25-event/ReservationI";

@TypeManagerDecorator("s25-ng-object-add-button")
@Component({
    selector: "s25-ng-object-add-button",
    template: `
        @if (init) {
            <div>
                @if (showAddQuantity) {
                    <span
                        ><input
                            type="number"
                            min="0"
                            [max]="max"
                            class="c-input c-margin-right--single"
                            id="quantity"
                            [(ngModel)]="quantity"
                            (change)="onChange()"
                    /></span>
                }
                <button [class]="buttonClass" (click)="click()" [disabled]="disabledButton">{{ buttonText }}</button>
            </div>
        }
    `,
    styles: [
        `
            input[type="number"] {
                width: 80px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectAddButtonComponent implements OnInit {
    @Input() data?: AvailLocationItems | AvailResourceItems;
    @Input() itemTypeId: Item.Ids;
    @Input() occ?: S25Reservation;
    @Output() onButtonClick: EventEmitter<EventMircotI> = new EventEmitter<EventMircotI>();

    init: boolean = false;
    buttonText: string;
    buttonClass: string;
    disabledButton: boolean = false;
    showAddQuantity: boolean = false;
    quantity: number = 1;
    getAddedItems: any[] = [];
    max: number = 1;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        if (this.itemTypeId === Item.Ids.Location) {
            this.getAddedItems =
                this.occ.locations[0]?.requested.concat(
                    this.occ.locations[0]?.draft,
                    this.occ.locations[0]?.reserved,
                ) || [];
        } else {
            this.data?.quantity !== "Unlimited" ? (this.max = this.data.quantity) : (this.max = 65536);
            this.getAddedItems =
                this.occ.resources[0]?.requested.concat(
                    this.occ.resources[0]?.draft,
                    this.occ.resources[0]?.reserved,
                ) || [];
        }

        let nodeName = this.itemTypeId === Item.Ids.Location ? "space_id" : "resource_id";

        let find = this.getAddedItems.find((item) => item.itemId === this.data?.[nodeName]);

        if (find) {
            this.buttonText = "Added";
            this.buttonClass = "aw-button aw-button--default";
            this.disabledButton = true;
        } else if (this.data?.checkedObj.availableDatesWithOverrides <= 0) {
            this.buttonText = "Unavailable";
            this.buttonClass = "aw-button aw-button--default";
            this.disabledButton = true;
        } else {
            this.buttonText =
                (["C", "F"].indexOf(this.data?.assign_perm) > -1 ? "Reserve" : "Request") +
                (S25Util.toBool(this.data?.hasConflicts) ? " Available" : "");
            this.buttonClass = S25Util.toBool(this.data?.hasConflicts)
                ? "aw-button aw-button--warning"
                : ["C", "F"].indexOf(this.data.assign_perm) > -1
                  ? "aw-button aw-button--success"
                  : "aw-button aw-button--primary";
            if (this.itemTypeId === Item.Ids.Resource) this.showAddQuantity = true;
        }

        this.init = true;
        this.cd.detectChanges();
    }

    async click() {
        this.buttonText = "Added";
        this.buttonClass = "aw-button aw-button--default";
        this.disabledButton = true;
        this.showAddQuantity = false;
        const objType = this.itemTypeId === Item.Ids.Location ? "location" : "resource";
        const data = await EventOccUtil.normalizeAddRsrvWSData(this.data, this.getAddedItems, objType, this.quantity);
        this.onButtonClick.emit(data);
        this.cd.detectChanges();
    }

    onChange() {
        if (this.data?.stock_level && this.quantity > this.data?.stock_level) {
            this.quantity = this.data?.stock_level;
        } else if (this.quantity > 65536) {
            this.quantity = 65536;
        }
        this.cd.detectChanges();
    }
}
