import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { S25NumWeeksChooserComponent } from "./s25.num.weeks.chooser.component";

@NgModule({
    declarations: [S25NumWeeksChooserComponent],
    imports: [CommonModule, FormsModule],
    providers: [S25NumWeeksChooserComponent],
    exports: [S25NumWeeksChooserComponent],
})
export class S25NumWeeksChooserModule {
    constructor() {}
}
