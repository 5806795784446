import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25NotificationPolicyComponent } from "./s25.notification.policy.component";
import { S25NotificationPolicyContactsComponent } from "./s25.notification.policy.contacts.component";

@NgModule({
    declarations: [S25NotificationPolicyComponent, S25NotificationPolicyContactsComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25ItemModule,
        S25DropdownPaginatedModule,
        S25LoadingInlineModule,
        S25MultiselectModule,
    ],
    providers: [S25NotificationPolicyComponent, S25NotificationPolicyContactsComponent],
    exports: [S25NotificationPolicyComponent, S25NotificationPolicyContactsComponent],
})
export class S25NotificationPolicyModule {
    constructor() {}
}
