/* this component initialize create for occ inline edit, event form might need to add more logics */

import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    NgZone,
    OnInit,
    Input,
    signal,
    ViewChild,
    QueryList,
    ViewChildren,
    ChangeDetectorRef,
    HostListener,
    Output,
    EventEmitter,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { Debounce } from "../../decorators/debounce.decorator";
import { FlsService } from "../../services/fls.service";
import { ContactService } from "../../services/contact.service";
import { UserprefService } from "../../services/userpref.service";
import { S25Util } from "../../util/s25-util";
import { SearchService } from "../../services/search/search.service";
import { Item } from "../../pojo/Item";
import {
    S25SearchDropdownComponent,
    SearchDropdownApi,
} from "../s25-dropdown/single-select/s25.search.dropdown.component";
import { DropDownItem } from "../../pojo/DropDownItem";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { SearchUtil } from "../../services/search/s25.search.util";
import { QLUtil } from "../s25-ql/s25ql.util";
import { S25QLTokenizer, Tokenizer } from "../s25-ql/s25ql.tokenizer";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Search } from "../s25-ql/s25.home.search.component";
import { S25QLModeller } from "../s25-ql/s25ql.modeller";
import { S25qlSearchSimpleInputComponent, SimpleSearchCriterion } from "../s25-ql/s25ql.search.simple.input.component";
import { AdvancedModel, AdvancedStep } from "../s25-ql/s25ql.search.advanced.criteria.component";
import { PreferenceService } from "../../services/preference.service";
import { S25ObjectSearchResultsListComponent } from "./s25.object.search.results.list.component";
import { S25EventOccurrencesService } from "../s25-event/s25-event-occurrences/s25.event.occurrences.service";
import { S25ProfileUtil } from "../s25-event/models/s25.profile";
import { S25Event } from "../s25-event/EventMicroI";
import { S25Reservation } from "../s25-event/ReservationI";
import { UrlParams } from "./s25.object.search.util";

import QLItemTypes = Tokenizer.QLItemTypes;
import Model = SearchCriteria.Model;
import Searches = SearchCriteria.Searches;
import Ids = Item.Ids;
import StepTypeId = SearchCriteria.StepTypeId;

@TypeManagerDecorator("s25-ng-object-search")
@Component({
    selector: "s25-ng-object-search",
    template: `
        @if (isInit) {
            <div class="flex c-margin-bottom--single">
                <label class="label">Auto-Load Starred:</label>

                <s25-toggle-button
                    (modelValueChange)="updatePerference('autoLoad', $event)"
                    [(modelValue)]="autoLoad"
                ></s25-toggle-button>
            </div>

            @if (itemTypeId === 4) {
                <div class="c-margin-bottom--single label">
                    <s25-ng-checkbox
                        class="c-margin-right--double"
                        [(modelValue)]="hideConflicts"
                        (modelValueChange)="updatePerference('25L_rsrvwiz_hide_unavail_sp', $event)"
                        >Hide Conflicts</s25-ng-checkbox
                    >
                    <s25-ng-checkbox
                        class="c-margin-right--double"
                        [(modelValue)]="enforceHeadcount"
                        (modelValueChange)="updatePerference('25L_rsrvwiz_enforce_headcount', $event)"
                        >Enforce Headcount
                    </s25-ng-checkbox>
                    <s25-ng-checkbox
                        [(modelValue)]="showLocationScore"
                        (modelValueChange)="updatePerference('ShowLocationScore', $event)"
                        >Show Location Satisfactions</s25-ng-checkbox
                    >
                </div>
            }

            @if (!state.isQLSearch && state.isAdvancedSearch === false) {
                <s25-simple-collapse [headerText]="'Search Filters'" [defaultCollapsed]="true">
                    <div class="collapseContainer c-margin-left--double c-margin-bottom--single">
                        <s25-ng-ql-search-simple-input
                            #simpleCriteriaInput
                            [type]="$any(itemTypeId)"
                            [(criteria)]="state.criteria"
                        ></s25-ng-ql-search-simple-input>
                    </div>
                </s25-simple-collapse>
            }

            <div class="flex c-margin-top--single c-margin-bottom--single">
                <span class="searchDropdown">
                    <s25-ng-search-dropdown
                        #searchDropdown
                        [hasFav]="true"
                        [(chosen)]="state.search"
                        (chosenChange)="selectSearch($event)"
                        [itemTypeId]="itemTypeId"
                        [allowNonQueryId]="true"
                        [searchEnabled]="true"
                        [placeholder]="'Saved Searches (optional)'"
                    ></s25-ng-search-dropdown>
                </span>
                <span>
                    <s25-ng-ql-search-input
                        [(value)]="state.input"
                        (valueChange)="onTextInputChange()"
                        [type]="$any(itemTypeId)"
                        [placeholder]="'Search ' + (state.itemName + 's' | titlecase)"
                        (search)="onSearch()"
                        (errorMessage)="onErrorMessage($event)"
                        [disabled]="!!state.search && state.isAdvancedSearch !== false"
                    ></s25-ng-ql-search-input>
                </span>
                <span class="buttons">
                    <button class="c-textButton borderRight" (click)="onReset()">Reset</button>
                    <button class="aw-button aw-button--primary" (click)="onSearch()">Search</button>
                </span>
            </div>

            @if (state.errorMessage) {
                <div class="errorMessage">
                    <pre class="ngBold">Error: {{ state.errorMessage }}</pre>
                </div>
            }

            @if (showResultsList) {
                <s25-ng-object-search-results-list
                    #searchResultList
                    [itemTypeId]="itemTypeId"
                    [params]="params"
                    [occ]="occ"
                    [profileId]="profileId"
                    [eventId]="eventId"
                    [showLocationScore]="showLocationScore"
                    [hideConflicts]="hideConflicts"
                    [isTempSearch]="state.isQLSearch"
                    [selectedItems]="selectedItems"
                    (refreshF)="onRefresh($event)"
                ></s25-ng-object-search-results-list>
            }
        }
    `,
    styles: `
        .wrapper {
            margin: 0 calc(100% / 12 + 5%);
            position: relative;
        }

        .flex {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .flex.center {
            justify-content: center;
        }

        .flex > * {
            /*  margin: auto 0; */
        }

        .flex.end {
            justify-content: flex-end;
        }

        .itemTypeDropdown,
        .searchChooser {
            z-index: 1000;
        }

        .searchChooser {
            margin-bottom: 2em;
        }

        .searchChooser s25-generic-dropdown {
            min-width: 12em;
        }

        .searchChooser s25-ng-search-dropdown {
            min-width: 17em;
        }

        ::ng-deep s25-ng-object-search .searchChooser s25-generic-dropdown .select2-results {
            max-height: calc(min(65vh, 800px));
        }

        ::ng-deep s25-ng-object-search .searchInput {
            min-width: 18em;
        }

        .errorMessage {
            padding: 0.5em 0;
            display: flex;
            justify-content: center;
        }

        .errorMessage > pre {
            color: #c00;
        }

        ::ng-deep .nm-party--on s25-ng-object-search .errorMessage > pre {
            color: #ff8b8b !important;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0.5em 1em;
            flex-wrap: wrap;
        }

        .buttons .c-textButton {
            height: 1.5em;
            padding: 0 0.5em;
        }

        .buttons .borderRight:not(:nth-last-child(2)) {
            border-right: 1px solid #9c9c9c;
        }

        .label {
            font-size: 16px !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectSearchComponent implements OnInit {
    @Input() itemTypeId?: number;
    @Input() profileId?: number;
    @Input() eventId?: number;
    @Input() occ?: S25Reservation;
    @Input() selectedItems?: number[] = [];
    @Output() refreshF = new EventEmitter();

    isInit: boolean;
    states: Search.State[];
    loggedIn: boolean;
    query: string = "";
    autoLoad: boolean = true;
    hideConflicts: boolean;
    enforceHeadcount: boolean;
    showLocationScore: boolean;
    showResultsList: boolean = false;
    params: UrlParams = {};
    criteria: string = "";
    inputParam: string = "";
    refresh: boolean = false;
    event: S25Event;
    expectedCount: number | null;
    state?: Search.State;
    disabled: boolean = false;

    @ViewChildren("searchDropdown") searchDropdown: QueryList<S25SearchDropdownComponent>;
    @ViewChild("simpleCriteriaInput") simpleCriteria: S25qlSearchSimpleInputComponent;
    @ViewChild("searchResultList") searchResultList: S25ObjectSearchResultsListComponent;
    @ViewChild("S25SearchDropdownComponent") searchDropdownComp: S25SearchDropdownComponent;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
        private occurrencesService: S25EventOccurrencesService,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const [objectTypeDropdownOptions, loggedIn, prefs] = await Promise.all([
            SearchService.getSubjectOptionsByPerms(),
            UserprefService.getLoggedIn(),
            PreferenceService.getPreferences([
                "25L_rsrvwiz_enforce_headcount",
                "ShowLocationScore",
                "25L_rsrvwiz_hide_unavail_sp",
                "auto_load_starred_sp",
                "auto_load_starred_rs",
            ]),
        ]);

        const event = this.occurrencesService.S25Event;
        const profile = S25ProfileUtil.getProfileByRsrvId(event.profile, this.occ.itemId);
        if (profile !== null) this.expectedCount = profile?.expectedCount;

        if (this.itemTypeId === Item.Ids.Location) {
            this.autoLoad = S25Util.toBool(prefs.auto_load_starred_sp.value);
            this.hideConflicts = S25Util.toBool(prefs["25L_rsrvwiz_hide_unavail_sp"].value);
            this.enforceHeadcount = S25Util.toBool(prefs["25L_rsrvwiz_enforce_headcount"].value);
            this.showLocationScore = S25Util.toBool(prefs.ShowLocationScore.value);
        } else {
            this.autoLoad = S25Util.toBool(prefs.auto_load_starred_rs.value);
        }

        this.loggedIn = loggedIn;
        this.states = objectTypeDropdownOptions.map(({ id, name }) => ({
            type: name as Search.Type,
            typeId: id,
            tab: "list",
            input: "",
            search: null,
            itemId: id,
            itemName: name,
            errorMessage: null,
            isAdvancedSearch: false,
            advanced: { model: { query_method: "all", step: [] }, searches: {} },
            isQLSearch: false,
            criteria: null,
        }));

        this.itemTypeId === Item.Ids.Location ? (this.state = this.states[1]) : (this.state = this.states[3]);

        await this.getPerms();

        // display favorite saved serach if auto load checked
        if (this.autoLoad) {
            this.query = "&favorite=T";
            let search = this.state.search as unknown as DropDownItem | string;
            this.itemTypeId === Item.Ids.Location ? (search = "&spaces_favorite=T") : (search = "&resource_favorite=T");

            // @ViewChildren creates a QueryList. Changes to the items in the QueryList can be subscribed to
            // through an RxJS Observable (this.searchDropdown.changes).
            // Subscribe to @ViewChildren(searchDropdown) and set the search once it has been loaded
            const subscriber = this.searchDropdown.changes
                .pipe()
                .subscribe((dropdown: QueryList<S25SearchDropdownComponent>) => {
                    // QueryList.first retrieves the first item in the QueryList
                    // In our case the QueryList will always have exactly one item (once it's loaded)
                    // but we use @ViewChildren in order to be able to subscribe
                    dropdown.first.selectSearch({
                        property: !Number(search) ? "val" : "itemId",
                        value: search,
                        itemTypeId: this.state.typeId,
                    });
                    subscriber.unsubscribe();
                });
        }

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    clearError() {
        this.state.errorMessage = null;
    }

    async selectSearch(search: any) {
        this.state.input = search.txt;
        this.state.isAdvancedSearch = undefined;
        this.state.isQLSearch = undefined;
        this.clearError(); // Reset any error
        this.state.hasSearched = true;
        await this.getPerms();
        this.query = search.val;
        this.inputParam = "";
        this.setParams();
    }

    onSearch() {
        this.clearError();
        if (this.state.search && this.state.isAdvancedSearch === undefined) return this.selectSearch(this.state.search);
        const input = this.state.input.trim();
        if (this.state.isQLSearch) return this.onQLSearch(input);
        this.setParams();
        return this.onKeywordSearch(this.state.input);
    }

    validateAdvancedSearch(): boolean {
        return true;
    }

    async onQLSearch(input: string) {
        const type = this.itemTypeId;
        const [data, error] = S25QLTokenizer.validateQL(input, type);
        if (error) this.state.errorMessage = error;
        if (!data) return;
        let { id, name, tempIds } = await SearchUtil.createSearch(data.model, {}, this.itemTypeId);

        this.query = "&query_id=" + id;
        this.inputParam = "";
        this.setParams();
        this.state.hasSearched = true;
    }

    validateKeywordSearch(input: string) {
        const params = this.simpleCriteria?.getParams() || "";

        if (input.length < 2 && !params) {
            this.state.errorMessage = "Keyword search must contain at least 2 characters";
            return false;
        }
        if (input.length > 48) {
            this.state.errorMessage = "Keyword searches may not exceed 48 characters";
            return false;
        }
        return true;
    }

    async onKeywordSearch(input: string) {
        if (!this.validateKeywordSearch(input)) return;
        this.criteria = this.simpleCriteria?.getParams() || "";
        this.inputParam = input ? `&name=${encodeURIComponent(input)}` : "";
        this.state.hasSearched = true;
        this.setParams();
    }

    onErrorMessage(message: string) {
        this.state.errorMessage = message;
    }

    @Bind
    onReset() {
        if (!this.state) return;
        this.state.input = "";
        this.state.search = null;
        this.state.isQLSearch = false;
        this.clearError();
        this.state.advanced = { model: { query_method: "all", step: [] }, searches: {} };
        this.state.criteria = null;
        this.state.hasSearched = false;
        if (this.state.isAdvancedSearch === undefined) this.state.isAdvancedSearch = false;
    }

    async searchToQL() {
        if (!this.state.search?.itemId) return;
        const search = await SearchService.getFullSearchCriteria(this.state.typeId, this.state.search.itemId as number);
        const serial = S25QLModeller.serialize(this.state.typeId, search.model, search.searches);
        this.setTextInput(serial);
    }

    getSearchModel() {
        return SearchService.getFullSearchCriteria(this.state.typeId, this.state.search.itemId as number);
    }

    getAdvancedModel() {
        // Replace any temp search containing only one step with its contents
        const { model, searches } = this.state.advanced;
        const steps = S25Util.array.flatten([model.step, Object.values(searches).map((s) => s.step)]);
        for (let step of steps) {
            const key = step.step_param?.[0]?.itemName;
            if (!QLUtil.isStepTemp(step) || !(key in searches) || searches[key].step.length > 1) continue;
            Object.assign(S25Util.clearObject(step), searches[key].step[0]); // Replace contents of step with new data
            delete searches[key]; // Remove temp search
        }
        return this.state.advanced;
    }

    getQLModel() {
        const type = this.state.typeId as QLItemTypes;
        const query = S25QLTokenizer.tokenizer(this.state.input, type);
        const model = S25QLModeller.model(type, query) as any;
        model.model.query_method ??= "all";
        return model;
    }

    async getKeywordModel(): Promise<{ model: AdvancedModel; searches: Searches }> {
        // Get criteria steps
        const steps: AdvancedStep[] = await this.simpleCriteria.getModel();

        // Get keyword step
        const input = this.state.input;
        if (input) {
            const stepTypeId = (this.state.typeId * 100 + 45) as StepTypeId; // Keyword is X45
            const step = AdvancedSearchUtil.getStep(this.state.typeId, stepTypeId);
            step.step_param[0].itemName = input;
            steps.unshift(step); // Want keyword first
        }

        return { model: { query_method: "all", step: steps }, searches: {} };
    }

    getModel() {
        if (this.state.isAdvancedSearch === undefined) return this.getSearchModel();
        if (this.state.isAdvancedSearch) return this.getAdvancedModel();
        if (this.state.isQLSearch) return this.getQLModel();
        return this.getKeywordModel();
    }

    setTextInput(text: string) {
        if (/^[\s\n\t]*::[\s\n\t]*$/i.test(text)) text = "";
        this.state.input = text;
        this.onTextInputChange();
    }

    onTextInputChange() {
        // Check if QL search
        this.state.isQLSearch = this.state.input && /^[\s\n\t]*::/.test(this.state.input);
    }

    reloadSearches(queryId: number) {
        const data = { itemTypeId: this.state.typeId, property: "itemId", value: queryId };
        SearchDropdownApi.reloadSearches(this.elementRef.nativeElement, data);
    }

    async getPerms() {
        const buttonPerms = await SearchService.getButtonPerms(!!this.state.search?.isOwner);
        for (let [type, perms] of Object.entries(buttonPerms)) {
            const state = this.states.find((state) => state.typeId === Number(type));
            if (state) state.perms = perms;
        }
    }

    refreshSearch() {
        if (this.state.isAdvancedSearch === undefined) return; // No changes have been made
        return this.searchToQL();
    }

    isKeywordSearch() {
        return this.state.isAdvancedSearch === false && !this.state.isQLSearch;
    }

    validate(): boolean {
        this.clearError();
        if (this.state.isAdvancedSearch && !this.validateAdvancedSearch()) return false;
        if (this.state.isQLSearch) {
            const [data, error] = S25QLTokenizer.validateQL(this.state.input, this.state.typeId as any);
            if (error) {
                this.onErrorMessage(error);
                return false;
            }
        }
        if (this.isKeywordSearch() && !this.validateKeywordSearch(this.state.input)) return false;

        return true;
    }

    updatePerference(prefName?: string, val?: any) {
        if (prefName === "autoLoad")
            this.itemTypeId === Item.Ids.Location ? (prefName = "auto_load_starred_sp") : "auto_load_starred_rs";
        return PreferenceService.setPreference(prefName, val).then(() => {
            if (prefName === "ShowLocationScore") this.setParams();
        });
    }

    @Debounce(300) // Avoid double calls
    setParams() {
        let criteria =
            "&can_schedule=T" +
            "&perm_start_dt=" +
            S25Util.date.toS25ISODateTimeStr(this.occ?.setupStart || this.occ.eventStart) +
            "&perm_end_dt=" +
            S25Util.date.toS25ISODateTimeStr(this.occ?.setupStart || this.occ.eventStart) +
            this.criteria;

        if (this.itemTypeId === Item.Ids.Location) criteria += "&scope=extended";

        let include: string | any = "";

        if (this.itemTypeId === Item.Ids.Location) {
            this.showLocationScore ? (include = "&include=layouts+ratings") : (include = "&include=layouts");
            if (this.enforceHeadcount) include = include + "&min_default_capacity=" + this.expectedCount || 0;
        }

        let query = "";

        if (["&spaces_favorite=T", "&resource_favorite=T"].indexOf(this.query) > -1) {
            query = "&favorite=T";
        } else if (["&spaces_direct=T&spaces_min_ols=R&spaces_can_assign=T"].indexOf(this.query) > -1) {
            query = this.query.replace(
                "&spaces_direct=T&spaces_min_ols=R&spaces_can_assign=T",
                "&direct=T&spaces_min_ols=R&spaces_can_assign=T",
            );
        } else {
            query = this.query;
        }

        if (this.inputParam && this.inputParam !== "") query = "";
        this.params = {
            inputParam: this.inputParam,
            query: query,
            criteria: criteria,
            include: include,
        };

        if (!this.refresh) {
            this.refresh = true;
        } else {
            this.showResultsList = false;
            this.changeDetector.detectChanges();
        }

        this.showResultsList = true;
        this.changeDetector.detectChanges();
    }

    onRefresh(e: any) {
        this.refreshF.emit(e); //To Action page, if itemAdded, then refresh the page
    }
}
