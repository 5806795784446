import { NgModule } from "@angular/core";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { CommonModule } from "@angular/common";
import { PopoverModule } from "../s25-popover/popover.module";
import { FormsModule } from "@angular/forms";
import { S25ObjectSearchComponent } from "./s25.object.search.component";
import { S25QLModule } from "../s25-ql/s25ql.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25ObjectModule } from "../s25-object/s25.object.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25ObjectSearchResultsListComponent } from "./s25.object.search.results.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25ConflictsModule } from "../s25-conflicts/s25.conflicts.module";
import { S25ObjectConflictComponent } from "./s25.object.conflict.component";
import { S25ObjectAddButtonComponent } from "./s25.object.add.button.component";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";

@NgModule({
    imports: [
        S25LoadingInlineModule,
        CommonModule,
        PopoverModule,
        FormsModule,
        S25QLModule,
        S25ModalModule,
        S25ObjectModule,
        S25DropdownPaginatedModule,
        S25ToggleButtonModule,
        S25CheckboxModule,
        S25SimpleCollapseModule,
        S25TableModule,
        S25ConflictsModule,
        S25ItemModule,
        S25PipesModule,
    ],
    exports: [
        S25ObjectSearchComponent,
        S25ObjectSearchResultsListComponent,
        S25ObjectConflictComponent,
        S25ObjectAddButtonComponent,
    ],
    declarations: [
        S25ObjectSearchComponent,
        S25ObjectSearchResultsListComponent,
        S25ObjectConflictComponent,
        S25ObjectAddButtonComponent,
    ],
    providers: [
        S25ObjectSearchComponent,
        S25ObjectSearchResultsListComponent,
        S25ObjectConflictComponent,
        S25ObjectAddButtonComponent,
    ],
})
export class S25ObjectSearchModule {}
