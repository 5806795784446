//@author travis
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-num-weeks-chooser")
@Component({
    selector: "s25-num-weeks-chooser",
    template: `
        <div>
            <select
                [(ngModel)]="weeks"
                (ngModelChange)="onChange.emit($event)"
                class="ngListPageDropdown ng-pristine ng-valid ng-not-empty ng-touched cn-form__control"
                aria-label="Select number of weeks"
            >
                @for (week of numWeeksArr; track week) {
                    <option [ngValue]="week">{{ week }}</option>
                }
            </select>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25NumWeeksChooserComponent {
    @Input() weeks: number = 1;
    @Output() onChange = new EventEmitter<number>();
    static NUM_WEEKS_CONST = 20;
    numWeeksArr: number[] = new Array(S25NumWeeksChooserComponent.NUM_WEEKS_CONST).fill(null).map((_, i) => i + 1);
}
