import { NgModule } from "@angular/core";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { CommonModule } from "@angular/common";
import { PopoverModule } from "../s25-popover/popover.module";
import { FormsModule } from "@angular/forms";
import { S25CreateObjectComponent } from "./s25.create.object.component";

@NgModule({
    imports: [S25LoadingInlineModule, CommonModule, PopoverModule, FormsModule],
    exports: [S25CreateObjectComponent],
    declarations: [S25CreateObjectComponent],
    providers: [S25CreateObjectComponent],
})
export class S25ObjectModule {}
