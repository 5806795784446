import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { AvailLocationItems, AvailResourceItems } from "./s25.object.search.util";

@TypeManagerDecorator("s25-ng-conflict")
@Component({
    selector: "s25-ng-object-conflict",
    template: `
        @if (init) {
            <div>
                @if (!hasConflict) {
                    <div>None</div>
                }
                @if (hasConflict) {
                    <div>
                        <button class="c-textButton" (click)="click()">Conflict Details</button>
                        <s25-ng-modal #conflictModal [title]="'Conflicts'" [size]="'xs'">
                            <ng-template #s25ModalBody>
                                <s25-ng-conflicts [model]="data.checkedObj.conflicts"></s25-ng-conflicts>
                            </ng-template>
                        </s25-ng-modal>
                    </div>
                }
            </div>
        }
    `,
})
export class S25ObjectConflictComponent implements OnInit {
    @Input() hasConflict?: boolean;
    @Input() data?: AvailLocationItems | AvailResourceItems;

    init: boolean = false;

    @ViewChild("conflictModal") conflictModal: S25ModalComponent;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    click() {
        this.conflictModal.open();
    }
}
